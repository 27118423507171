<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">{{$t("_forgot_password.Recover_your_password")}}</h4>
                  <p>{{$t("_forgot_password.Recover_Message")}}</p>
                </div>

                <v-form ref="form" v-model="validateForm" :lazy-validation="lazy">
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    :label="$t('_forgot_password.E_mail')"
                    required
                    v-on:keyup.enter="forgotPassword()"
                  ></v-text-field>
                  <div>
                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                  </div>
                  <br />
                  <vs-button
                    type="border"
                    to="/pages/login"
                    class="px-4 w-full md:w-auto"
                  >{{$t('_forgot_password.Back_To_Login')}}</vs-button>

                  <vs-button
                    class="float-right"
                    :disabled="!validateForm"
                    @click="forgotPassword"
                  >{{$t('_forgot_password.Recover_Password')}}</vs-button>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      email: "",
      validation: {
        email: this.$goc.validate.create().required().email()
      },
      emailRules: [
        v => {
          return !!v || "E-mail is required";
        },
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      validateForm: true
    };
  },
  methods: {
    forgotPassword() {
      this.$goc.request.post(
        this.$enums.API.AuthenticationForgotPassword,
        { Email: this.email },
        () => {
          this.$goc.notify.success({
            title: "Message",
            message: "Your password reset link send your email address"
          });
        }
      );
    }
  }
};
</script>
